<template>
  <v-card class="auth-card">
    <!-- logo -->
    <v-card-title class="d-flex align-center justify-center py-7">
      <router-link
        to="/home"
        class="d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-width="150px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>
      </router-link>
      <CardI18n />
    </v-card-title>

    <!-- title -->
    <v-card-text>
      <div class="primary--text text-center mb-4">
        {{ $t('LeGrandJardin') }}
      </div>
      <p class="text-center text-2xl font-weight-semibold text--primary mb-2">
        {{ $t('UpdatePassword') }}
      </p>
      <p class="mb-1">
        {{ $t('PhoneNumber') }}: {{ phoneNumber }}
      </p>
    </v-card-text>

    <!-- login form -->
    <v-card-text>
      <v-form
        ref="setPasswordForm"
        @submit.prevent="handleFormSubmit"
      >
        <v-text-field
          v-model="password"
          dense
          outlined
          :type="isPasswordVisible ? 'text' : 'password'"
          :label="$t('NewPassword')"
          :placeholder="$t('NewPassword')"
          :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
          hide-details="auto"
          class="mb-3"
          :rules="[validators.required, validators.passwordValidator]"
          validate-on-blur
          @click:append="isPasswordVisible = !isPasswordVisible"
        ></v-text-field>

        <v-text-field
          v-model="confirmPassword"
          dense
          outlined
          :type="isConfirmPasswordVisible ? 'text' : 'password'"
          :label="$t('ConfirmPassword')"
          :placeholder="$t('ConfirmPassword')"
          :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
          hide-details="auto"
          :rules="[
            validators.required, validators.confirmPasswordValidator(password, confirmPassword)
          ]"
          validate-on-blur
          @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
        ></v-text-field>

        <v-btn
          block
          color="primary"
          type="submit"
          class="mt-6"
        >
          {{ $t('Submit') }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { required, passwordValidator, confirmPasswordValidator } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import CardI18n from '@/components/CardI18n.vue'
import store from '@/store'
import AccountService from '@/services/AccountService'

export default {
  components: { CardI18n },
  props: {
    phoneNumber: {
      type: Number,
      required: true,
    },
    otp: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // Template Refs
    const setPasswordForm = ref(null)
    const { router } = useRouter()

    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const password = ref('')
    const confirmPassword = ref('')
    const loading = ref(false)

    const handleFormSubmit = async () => {
      const isFormValid = setPasswordForm.value.validate()

      if (!isFormValid) return

      loading.value = true
      try {
        await AccountService.changePasswordWithOTP({
          phone: props.phoneNumber.toString(),
          password: password.value,
          otp: props.otp,
        })

        store.commit('snackbar/show', {
          type: 'success',
          message: 'Change Password Successfully',
        })

        router.push('/login')
      } catch (error) {
        console.error(error)
        store.commit('snackbar/show', {
          message: error.message,
          type: 'error',
        })
      } finally {
        loading.value = false
      }
    }

    return {
      isPasswordVisible,
      isConfirmPasswordVisible,
      password,
      confirmPassword,
      handleFormSubmit,
      validators: {
        required,
        passwordValidator,
        confirmPasswordValidator,
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // Template Refs
      setPasswordForm,
    }
  },
}
</script>

<template>
  <v-card class="auth-card">
    <!-- logo -->
    <v-card-title class="d-flex align-center justify-center py-7">
      <router-link
        to="/home"
        class="d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-width="150px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>
      </router-link>
      <CardI18n />
    </v-card-title>

    <!-- title -->
    <v-card-text>
      <div class="primary--text text-center mb-4">
        {{ $t('LeGrandJardin') }}
      </div>
      <p class="text-center text-2xl font-weight-semibold text--primary mb-2">
        {{ $t('ForgotPassword') }}
      </p>
      <p class="mb-2">
        {{ $t('ForgotPasswordSubtitle') }}
      </p>
    </v-card-text>

    <!-- login form -->
    <v-card-text>
      <v-form
        ref="confirmOTPForm"
        @submit.prevent="handleFormSubmit"
      >
        <v-text-field
          v-model="phoneNumber"
          dense
          outlined
          type="tel"
          :label="$t('PhoneNumber')"
          :placeholder="$t('PhoneNumber')"
          :rules="[validators.required, validators.phoneNumberValidator]"
          hide-details="auto"
          class="mb-3"
          validate-on-blur
          :error-messages="errorMessage"
        >
        </v-text-field>
        <v-btn
          block
          color="primary"
          type="submit"
          class="mt-6"
          :loading="checkingPhone"
        >
          {{ $t('Submit') }}
        </v-btn>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex justify-center align-center">
      <router-link
        :to="{name:'auth-login'}"
        class="d-flex align-center text-sm"
      >
        <v-icon
          size="24"
          color="primary"
        >
          {{ icons.mdiChevronLeft }}
        </v-icon>
        <span>{{ $t('BackToLogin') }}</span>
      </router-link>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiChevronLeft, mdiReload } from '@mdi/js'
import { required, phoneNumberValidator } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import CardI18n from '@/components/CardI18n.vue'
import axios from '@/plugins/axios'
import store from '@/store'

export default {
  components: { CardI18n },
  emits: 'onSubmitInformation',
  setup(_, { emit }) {
    const confirmOTPForm = ref(null)

    const errorMessage = ref('')

    const phoneNumber = ref('')
    const checkingPhone = ref(false)

    const handleFormSubmit = async () => {
      const isFormValid = confirmOTPForm.value.validate()

      if (!isFormValid) return

      checkingPhone.value = true
      try {
        const resp = await axios.post('/v1/account/phone/check', {
          phone: phoneNumber.value,
        })
        const isPhoneNumberExisted = resp.data.data.result

        if (!isPhoneNumberExisted) {
          store.commit('snackbar/show', {
            message: 'This phone number has not existed',
            type: 'error',

          })
          errorMessage.value = 'This phone number has not existed'
        } else {
          emit('onSubmitInformation', phoneNumber.value)
        }
      } catch {
      } finally {
        checkingPhone.value = false
      }
    }

    return {
      checkingPhone,
      phoneNumber,
      errorMessage,
      handleFormSubmit,
      icons: {
        mdiReload,
        mdiChevronLeft,
      },
      validators: {
        required,
        phoneNumberValidator,
      },

      // Template Refs
      confirmOTPForm,
    }
  },
}
</script>

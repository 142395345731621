<template>
  <v-card class="auth-card">
    <!-- logo -->
    <v-card-title class="d-flex align-center justify-center py-7">
      <router-link
        to="/home"
        class="d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-width="150px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>
      </router-link>
      <CardI18n />
    </v-card-title>
    <v-card-text>
      <div class="primary--text text-center mb-4">
        {{ $t('LeGrandJardin') }}
      </div>
      <p class="text-center text-2xl font-weight-semibold text--primary mb-2">
        {{ $t('ConfirmOTP') }}
      </p>
      <p class="mb-2">
        {{ $t('ConfirmOTPSubtitle', { phoneNumber: phoneNumber }) }}
      </p>
    </v-card-text>

    <!-- login form -->
    <v-card-text>
      <v-form
        ref="confirmOTPForm"
        @submit.prevent="handleFormSubmit"
      >
        <v-text-field
          v-model="otp"
          dense
          outlined
          :label="$t('EnterOTP')"
          :placeholder="$t('EnterOTP')"
          :rules="[validators.required]"
          hide-details="auto"
          class="mb-3"
          validate-on-blur
          :suffix="time > 0 ? $t('ResendAfterTime', {time: time}): ''"
          :append-icon="time > 0 ? '' : icons.mdiReload"
          @click:append="handleGetOtp"
        >
          <!-- <template
            v-slot:append
          >
            <i18n
              v-if="time"
              path="ResendAfterTime"
              class="mt-1"
            >
              <template #time>
                {{ time }}
              </template>
            </i18n>
            <a
              v-else
              class="mt-1"
              @click="handleGetOtp"
            >{{ $t('Resend') }}
            </a>
          </template> -->
        </v-text-field>

        <v-btn
          block
          color="primary"
          type="submit"
          class="mt-6"
          :loading="loading"
        >
          {{ $t('Submit') }}
        </v-btn>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex justify-center align-center">
      <a @click="handleGoBack">
        <v-icon
          size="24"
          color="primary"
        >
          {{ icons.mdiChevronLeft }}
        </v-icon>
        <span>{{ $t('Back') }}</span>
      </a>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiChevronLeft, mdiReload } from '@mdi/js'
import { required } from '@core/utils/validation'
import { onMounted, ref, watch } from '@vue/composition-api'
import CardI18n from '@/components/CardI18n.vue'
import store from '@/store'
import axios from '@/plugins/axios'
import { useIntervalFn } from '@vueuse/core'

const TIMER = 120

export default {
  components: { CardI18n },
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
  },
  emits: ['onBack', 'onSubmit'],
  setup(props, { emit }) {
    const confirmOTPForm = ref(null)

    const otp = ref('')
    const loading = ref(false)

    const time = ref(0)

    const { resume: startTimer, pause: pauseTimer } = useIntervalFn(
      () => {
        time.value -= 1
      },
      1000,
      false,
    )

    const resetTimer = () => {
      time.value = TIMER
      startTimer()
    }

    watch(time, value => {
      if (value === 0) {
        pauseTimer()
      }
    })

    const handleGetOtp = async (isResetTimer = true) => {
      try {
        const resp = await axios.post('/v1/account/getOtp', {
          phone: props.phoneNumber,
          action_otp: 'forgot_password',
        })

        if (isResetTimer) {
          resetTimer()
        }

        // ! Hardcode OTP
        otp.value = resp.data.data.otp_debug
      } catch {}
    }

    onMounted(() => {
      handleGetOtp(true)
    })

    const handleFormSubmit = async () => {
      const isFormValid = confirmOTPForm.value.validate()

      if (!isFormValid) return
      loading.value = true
      try {
        await axios.post('/v1/account/checkOtp', {
          phone: props.phoneNumber,
          otp_code: otp.value,
        })

        emit('onSubmit', otp.value)
      } catch (error) {
        store.commit('snackbar/show', {
          message: error.message,
          type: 'error',
        })
      } finally {
        loading.value = false
      }
    }

    const handleGoBack = () => {
      emit('onBack')
    }

    return {
      otp,
      time,
      loading,
      handleFormSubmit,
      handleGoBack,
      handleGetOtp,
      icons: {
        mdiReload,
        mdiChevronLeft,
      },
      validators: {
        required,
      },

      // Template Refs
      confirmOTPForm,
    }
  },
}
</script>

/* eslint-disable class-methods-use-this */
import axios from '@/plugins/axios'

class AccountService {
  requestForgotPassword(data) {
    return axios.post('/v1/account/user/forgotPassword', data)
  }

  changePasswordWithOTP(data) {
    return axios.post('/v1/account/user/changePasswordOtp', data)
  }

  changePassword(data) {
    return axios.post('/v1/account/user/changePassword', data)
  }

  changePasswordUser(id, data) {
    return axios.post(`/v1/account/user/changePasswordUser/${id}`, data)
  }

  changePhoneNumberWithOTP(data) {
    return axios.put('/v1/account/changePhoneOtp', data)
  }

  getListUserActivities(params) {
    return axios.get('/v1/account/user/activity', { params })
  }

  getListUsers(params) {
    return axios.get('/v1/account/user', { params })
  }

  getUserProfile(params) {
    return axios.get('/v1/account/user/profile', { params })
  }

  getUserFamily(params) {
    return axios.get('/v1/account/family', { params })
  }

  getUserRole() {
    return axios.get('/v1/account/roles/')
  }

  updateUserInfo(id, data) {
    return axios.put(`/v1/account/update/user/${id}`, data)
  }

  lockUnlockedUser(id, data) {
    return axios.put(`/v1/account/lock/user/${id}`, data)
  }

  getRoles() {
    return axios.get('/v1/account/roles')
  }

  assignRoleToUser(id, data) {
    return axios.put(`/v1/account/role/assign/${id}`, data)
  }

  deleteUser(id) {
    return axios.delete(`/v1/account/user/${id}`)
  }

  checkPhoneNumber(data) {
    return axios.post('/v1/account/phone/check', data)
  }

  checkMyPassword(data) {
    return axios.post('/v1/account/password/check', data)
  }

  registerUser(data) {
    return axios.post('/v1/account/user/register', data)
  }

  registerUserPassword(data) {
    return axios.post('/v1/account/user/register/password', data)
  }

  getOTP(data) {
    return axios.post('/v1/account/getOtp', data)
  }

  checkOTP(data) {
    return axios.post('/v1/account/checkOtp', data)
  }
}

export default new AccountService()

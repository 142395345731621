<template>
  <div class="card-language">
    <AppBarI18n />
  </div>
</template>
<script>
import AppBarI18n from '@/components/AppBarI18n.vue'

export default {
  components: { AppBarI18n },
}
</script>
<style lang="scss" scoped>
.card-language {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
</style>

<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <ForgotPasswordOTP
        v-if="step===1"
        :phone-number="phoneNumber"
        @onBack="onBackToInformation"
        @onSubmit="onSubmitOTP"
      />
      <UpdatePassword
        v-else-if="step===2"
        :phone-number="phoneNumber"
        :otp="otp"
      />
      <ForgotPasswordInformation
        v-else
        @onSubmitInformation="onSubmitInformation"
      />
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import ForgotPasswordInformation from './components/ForgotPasswordInformation.vue'
import ForgotPasswordOTP from './components/ForgotPasswordOTP.vue'
import UpdatePassword from './components/UpdatePassword.vue'

const INFORMATION_STEP = 0
const ENTER_OTP_STEP = 1
const UPDATE_PASSWORD_STEP = 2

export default {
  components: { ForgotPasswordInformation, ForgotPasswordOTP, UpdatePassword },
  data() {
    return {
      step: 0,
      phoneNumber: '',
      otp: '',
    }
  },

  methods: {
    onSubmitInformation(phoneNumber) {
      this.phoneNumber = phoneNumber
      this.step = ENTER_OTP_STEP
    },
    onSubmitOTP(otp) {
      this.otp = otp
      this.step = UPDATE_PASSWORD_STEP
    },
    onBackToInformation() {
      this.step = INFORMATION_STEP
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
